export class Vat {
    public label: string
    public value: string

    constructor (label: string, value: string) {
        this.label = label
        this.value = value
    }

    public static list: Array<Vat> = [
        new Vat("5,50 %", "5.50"),
        new Vat("10,00 %", "10.00"),
        new Vat("20,00 %", "20.00")
    ]
}