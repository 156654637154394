import { Component, OnInit } from '@angular/core';

declare const $: any;
declare interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
}
export const ROUTES: Array<{category:string, routes:Array<RouteInfo>}> = [
    { category: 'home', routes : [{path: '/home', title: 'Accueil',  icon: 'home', class: '' }] },,
    { category: 'établissement', routes: [
        { path: '/establishment/info', title: 'Informations',  icon: 'info', class: '' },
        { path: '/establishment/options/1', title: 'Options',  icon:'settings', class: '' },
        { path: '/establishment/comments', title: 'Comments',  icon:'comment', class: '' },
        { path: '/establishment/printer', title: 'Imprimantes',  icon:'print', class: '' },
    ] },
    { category: 'équipe', routes: [
        { path: '/team/staff', title: 'Employés',  icon: 'people', class: '' },
        { path: '/team/planning', title: 'Planning',  icon:'calendar_today', class: '' },
    ] },
    { category: 'carte', routes: [
        { path: '/product/simple', title: 'Produits',  icon: 'shopping_basket', class: '' },
        { path: '/product/extend', title: 'Composition',  icon:'list', class: '' },
        { path: '/product/set', title: 'Formules',  icon:'assignment', class: '' },
    ] },
];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  menuItems: any[];

  constructor() { }

  ngOnInit() {
    this.menuItems = ROUTES.filter(menuItem => menuItem);
  }
  isMobileMenu() {
      if ($(window).width() > 991) {
          return false;
      }
      return true;
  };
}
