import { NgModule } from '@angular/core'
import { CommonModule, JsonPipe } from '@angular/common'

import { SwitchComponent } from './switch/switch.component'
import { ToggleComponent } from './toggle/toggle.component'

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        SwitchComponent,
        ToggleComponent
    ],
    entryComponents: [
        SwitchComponent,
        ToggleComponent
    ],
    exports: [
        SwitchComponent,
        ToggleComponent
    ]
})
export class ComponentsModule { }