export class Staff {

    public staff_id: number
    public first_name: string
    public last_name: string
    public role: string

    public color: string
    public email: string
    public login: string
    public phone: string

    public reduction: boolean
    public cash_drawer: boolean
    public remove_order: boolean

    constructor (staff_id?: number, first_name?: string, last_name?: string, role?: string, color?: string, email?: string, login?: string, phone?: string, reduction: boolean = false, cash_drawer: boolean = false, remove_order: boolean = false) {
        this.staff_id = staff_id
        this.first_name = first_name
        this.last_name = last_name
        this.role = role

        this.color = color
        this.email = email
        this.login = login
        this.phone = phone

        this.reduction = reduction
        this.cash_drawer = cash_drawer
        this.remove_order = remove_order
    }
}