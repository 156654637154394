export class Endpoint {

    public static "auth": Endpoint = new Endpoint("Authentification", "http://localhost", "auth")
    public static "common": Endpoint = new Endpoint("Manager", "http://localhost", "manager", "parameter")
    public static "product": Endpoint = new Endpoint("Product", "http://localhost", "manager", "parameter/product")
    public static "category": Endpoint = new Endpoint("Category", "http://localhost", "manager", "parameter/category")
    public static "stock": Endpoint = new Endpoint("Stock", "http://localhost", "manager", "stock")
    public static "staff": Endpoint = new Endpoint("Staff", "http://localhost", "manager", "parameter/staff")
    public static "printer": Endpoint = new Endpoint("Printer", "http://localhost", "manager", "parameter/printer")
    public static "options": Endpoint = new Endpoint("Options", "http://localhost", "manager", "parameter/option")

    public name: string
    public basepath: string
    public module: string
    public resource: string
    public port: number

    constructor (name: string, basepath: string, module: string, resource?: string, port?: number) {
        this.name = name
        this.basepath = basepath
        this.module = module
        this.resource = resource
        this.port = port
    }

    public get(): string {
        let path: string = this.basepath
        if (this.port) path += `:${this.port}`
        if (this.module) path += `/${this.module}`
        if (this.resource) path += `/${this.resource}`
        return path
    }
}