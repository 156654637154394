export class Type {
    public label: string
    public value: string

    constructor (label: string, value: string) {
        this.label = label
        this.value = value
    }

    public static list: Array<Type> = [
        new Type("Produit", "SIMPLE"),
        new Type("Composition", "EXTEND"),
        new Type("Formule", "SET")
    ]
}