import { Injectable } from '@angular/core'

import { Httpclient } from './Httpclient.service';
import { Endpoint } from 'app/models/Endpoint';
import { Printer, PrinterTarget } from "app/models"
import { Status } from 'app/models/Status';

@Injectable()
export class PrinterService {

    public endpoint: string

    constructor (
        private _Httpclient: Httpclient
    ) {
        this.endpoint = Endpoint["printer"].get()
    }

    public async get (): Promise<Array<Printer>> {
        try {
            let res = await this._Httpclient.get(`${this.endpoint}/all`)
            let printers = res.map(printer => new Printer(
                printer.printer_id,
                printer.name,
                printer.kind,
                printer.placement
            ))
            return Promise.resolve(printers)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    public async find (id: number): Promise<Printer> {
        try {
            let res = await this._Httpclient.get(`${this.endpoint}/${id}`)
            let printer = new Printer(
                res.printer_id,
                res.name,
                res.kind,
                res.placement,
                res.target.map(target => new PrinterTarget(target.model, target.mac_address, res.printer_id, target.printer_target_id))
            )
            return Promise.resolve(printer)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    public async add (printer: Printer): Promise<Status> {
        try {
            let res = await this._Httpclient.post(`${this.endpoint}`, printer)
            return Promise.resolve(new Status(res))
        } catch (error) {
            return Promise.reject(error)
        }
    }

    public async edit (id: number, printer: Printer): Promise<Status> {
        try {
            let res = await this._Httpclient.put(`${this.endpoint}/${id}`, printer)
            return Promise.resolve(new Status(res))
        } catch (error) {
            return Promise.reject(error)
        }
    }

    public async delete (id: number): Promise<Status> {
        try {
            let res = await this._Httpclient.delete(`${this.endpoint}/${id}`)
            return Promise.resolve(new Status(res))
        } catch (error) {
            return Promise.reject(error)
        }
    }
}