import { PrinterTarget } from "./PrinterTarget";

export class Printer {

    public printer_id: number
    public name: string
    public kind: string
    public placement: string
    public target: Array<PrinterTarget>
    
    constructor (printer_id?: number, name?: string, kind?: string, placement?: string, target: Array<PrinterTarget> = []) {
        this.printer_id = printer_id
        this.name = name
        this.kind = kind
        this.placement = placement
        this.target = target
    }
}