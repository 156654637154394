export class ProductItem {
    public product_item_id: number
    public product_id: number
    public name: string
    public quantity: number
    public stock_id: number
    public type: string

    public static custom: ProductItem = new ProductItem(null, null, null, null, null, "CUSTOM")

    constructor (product_id: number, name?: string, quantity?: number, stock_id?: number, product_item_id?: number, type?: string) {
        this.product_id = product_id
        this.product_item_id = product_item_id
        this.name = name
        this.quantity = quantity
        this.stock_id = stock_id
        this.type = type
    }
}