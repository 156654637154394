export class PrinterPlacement {
    public label: string
    public value: string

    constructor (label: string, value: string) {
        this.label = label
        this.value = value
    }

    public static list: Array<PrinterPlacement> = [
        new PrinterPlacement("Comptoir", "COUNTER"),
        new PrinterPlacement("Cuisine", "KITCHEN"),
        new PrinterPlacement("Packaging", "PICKING"),
        new PrinterPlacement("Bar", "BAR"),
        new PrinterPlacement("Chaud", "HOT"),
        new PrinterPlacement("Froid", "COLD"),
        new PrinterPlacement("Dessert", "DESSERT")
    ]
}