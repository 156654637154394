import { Category } from "./Category";
import { ProductItem } from "./ProductItem";

export class Product {
    public product_id: number
    public product_extend_id: number
    public product_set_id: number
    public type: string
    public name: string
    public color: string
    public price: number
    public vat: number
    public priceNoVat: number
    public title: string
    public icon: string
    public className: string
    public association: Array<Category>
    public items: Array<ProductItem>

    public designation: string
    public description: string

    public printer_placement: string

    constructor (product_id: number, type: string, name: string, color: string, price?: number, vat?: number, priceNoVat?: number, association: Array<Category> = [], items: Array<ProductItem> = [], product_set_id?: number, product_extend_id?: number, designation?: string, description?: string, printer_placement?: string) {
        this.product_id = product_id
        this.type = type
        this.name = name
        this.color = color
        this.price = price
        this.vat = vat
        this.priceNoVat = priceNoVat
        this.title = Product.findTitle(type)
        this.icon = Product.findIcon(type)
        this.className = Product.findClass(type)
        this.association = association
        this.items = items
        this.product_set_id = product_set_id
        this.product_extend_id = product_extend_id
        this.designation = designation
        this.description = description
        this.printer_placement = printer_placement
    }

    public static create (type: string): Product {
        let name: string;
        switch (type.toUpperCase()) {
          case "SIMPLE":
            name = "Nouveau produit"
            break;
          case "EXTEND":
            name = "Nouvelle composition"
            break;
          case "SET":
            name = "Nouvelle formule"
            break;
          default:
            name = "Nouveau produit"
            break;
        }
        return new Product(null, type.toUpperCase(), name, "COLOR_WHITE")
    }

    public static findTitle (type: string): string {
        let title: string
        switch (type) {
            case "SIMPLE":
                title = "Produits"
                break
            case "EXTEND":
                title = "Compositions"
                break
            case "SET":
                title = "Formules"
                break
            default:
                title = "Produits"
                break
        }
        return title
    }

    public static findIcon (type: string): string {
        let icon: string
        switch (type) {
            case "SIMPLE":
                icon = "shopping_basket"
                break
            case "EXTEND":
                icon = "list"
                break
            case "SET":
                icon = "assignment"
                break
            default:
                icon = "clear"
                break
        }
        return icon
    }

    public static findClass (type: string): string {
        let className: string
        switch (type) {
            case "SIMPLE":
                className = "warning"
                break
            case "EXTEND":
                className = "success"
                break
            case "SET":
                className = "info"
                break
            default:
                className = "primary"
                break
        }
        return className
    }
}