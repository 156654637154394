import { Injectable } from '@angular/core'

import { Category } from "app/models"
import { Httpclient } from './Httpclient.service';
import { Endpoint } from 'app/models/Endpoint';

@Injectable()
export class CategoryService {

    public endpoint: string

    constructor (
        private _Httpclient: Httpclient
    ) {
        this.endpoint = Endpoint["category"].get()
    }

    public async getType (type: string): Promise<Array<Category>> {
        try {
            let res = await this._Httpclient.get(`${this.endpoint}/type/${type}`)
            let categories = res.map(category => new Category(
                category.name,
                category.min,
                category.max,
                [],
                (res.type === "SET") ? category.category_set_id : category.category_extend_id,
                (res.type === "SET") ? "SET" : "EXTEND"
            ))
            return Promise.resolve(categories)
        } catch (error) {
            return Promise.reject(error)
        }
    }
}