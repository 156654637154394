export class Role {
    public label: string
    public value: string

    constructor (label: string, value: string) {
        this.label = label
        this.value = value
    }

    public static list: Array<Role> = [
        new Role("Gérant", "ADMIN"),
        new Role("Manager", "MANAGER"),
        new Role("Employés", "OPS")
    ]
}