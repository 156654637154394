import { Component, Input, Output, EventEmitter } from '@angular/core'

@Component({
    selector: 'toggle',
    templateUrl: './toggle.component.html'
})
export class ToggleComponent {
    @Input() on: boolean
    @Input() readonly: boolean

    @Output() toggled: EventEmitter<boolean> = new EventEmitter

    public handleClick(): void {
        if (!this.readonly) {
            this.on = !this.on
            this.toggled.emit(this.on)
        }
    }
}