export class Establishment {

    public static default: Establishment = new Establishment(null, null, false)

    public name: string
    public accessKey: string
    public active: boolean

    public trade_name: string
    public corporate_name: string
    public tva: string
    public siret: string
    public naf: string
    public longitude: string
    public latitude: string
    public phone: string
    public address: string
    public postal_code: string
    public city: string

    constructor (
        name?: string, 
        accessKey?: string, 
        active: boolean = true, 

        trade_name?: string, 
        corporate_name?: string, 
        tva?: string,
        siret?: string,
        naf?: string,
        longitude?: string,
        latitude?: string,
        phone?: string,
        address?: string,
        postal_code?: string,
        city?: string
    ) {
        this.name = name
        this.accessKey = accessKey
        this.active = active

        this.trade_name = trade_name
        this.corporate_name = corporate_name
        this.tva = tva
        this.siret = siret
        this.naf = naf
        this.longitude = longitude
        this.latitude = latitude
        this.phone = phone
        this.address = address
        this.postal_code = postal_code
        this.city = city
    }
}