import { Association } from "./Association";

export class Category {
    // Cateory set
    public category_set_id: number
    // category extend
    public category_extend_id: number
    // Common
    public name: string
    public min: number
    public max: number
    public product: Array<Association>
    public type: string
    public category_id: number|string

    public static custom: Category = new Category(null, null, null, [], 999999, "CUSTOM")

    constructor (name?: string, min?: number, max?: number, product: Array<Association> = [], category_id?: number, type?: string) {
        this.name = name
        this.min = min
        this.max = max
        this.product = product
        this.category_id = category_id
        if (type === "SET") this.category_set_id = category_id
        if (type === "EXTEND") this.category_extend_id = category_id
        this.type = type
    }
}