import { Injectable } from '@angular/core'
import { Md5 } from 'ts-md5'

import { Httpclient } from './Httpclient.service'
import { Endpoint } from 'app/models/Endpoint'
import { Status } from 'app/models/Status'
import { Staff } from "app/models"

@Injectable()
export class StaffService {

    public endpoint: string

    constructor (
        private _Httpclient: Httpclient
    ) {
        this.endpoint = Endpoint["staff"].get()
    }

    public async get (): Promise<Array<Staff>> {
        try {
            let res = await this._Httpclient.get(`${this.endpoint}/all`)
            let staffs = res.map(staff => new Staff(
                staff.staff_id,
                staff.first_name,
                staff.last_name,
                staff.role
            ))
            return Promise.resolve(staffs)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    public async find (id: number): Promise<Staff> {
        try {
            let res = await this._Httpclient.get(`${this.endpoint}/${id}`)
            let staff = new Staff(
                res.staff_id,
                res.first_name,
                res.last_name,
                res.role,
                `rgb(${res.color.split(',').map(c => c.split('.')[0]).join(',')})`,
                res.email,
                res.login,
                res.phone,
                res.reduction === "TRUE",
                res.cash_drawer === "TRUE",
                res.remove_order === "TRUE"
            )
            return Promise.resolve(staff)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    public async add (staff: Staff): Promise<Status> {
        try {
            let body = {
                ...staff,
                color: staff.color.substr(4, staff.color.length - 5),
                reduction: staff.reduction ? "TRUE" : "FALSE",
                cash_drawer: staff.cash_drawer ? "TRUE" : "FALSE",
                remove_order: staff.remove_order ? "TRUE" : "FALSE",
                password: Md5.hashStr('123456')
            }
            let res = await this._Httpclient.post(`${this.endpoint}`, body)
            return Promise.resolve(new Status(res))
        } catch (error) {
            return Promise.reject(error)
        }
    }

    public async edit (id: number, staff: Staff): Promise<Status> {
        try {
            let body = {
                ...staff,
                color: staff.color.substr(4, staff.color.length - 5),
                reduction: staff.reduction ? "TRUE" : "FALSE",
                cash_drawer: staff.cash_drawer ? "TRUE" : "FALSE",
                remove_order: staff.remove_order ? "TRUE" : "FALSE",
            }
            let res = await this._Httpclient.put(`${this.endpoint}/${id}`, body)
            return Promise.resolve(new Status(res))
        } catch (error) {
            return Promise.reject(error)
        }
    }

    public async delete (id: number): Promise<Status> {
        try {
            let res = await this._Httpclient.delete(`${this.endpoint}/${id}`)
            return Promise.resolve(new Status(res))
        } catch (error) {
            return Promise.reject(error)
        }
    }
}