import { Injectable } from '@angular/core'

import { Httpclient } from './Httpclient.service'
import { Endpoint } from 'app/models/Endpoint'
import { Stock } from "app/models"

@Injectable()
export class StockService {

    public endpoint: string

    constructor(
        private _Httpclient: Httpclient
    ) {
        this.endpoint = Endpoint["stock"].get()
    }

    public async get (): Promise<Array<Stock>> {
        try {
            let res = await this._Httpclient.get(`${this.endpoint}`)
            let stocks = res.map(stock => new Stock(stock.stock_id, stock.nomination))
            return Promise.resolve(stocks)
        } catch (error) {
            return Promise.reject(error)
        }
    }
}