import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { HttpModule } from '@angular/http'
import { RouterModule } from '@angular/router'
import { NgSelectModule } from '@ng-select/ng-select'
import { ColorPickerModule } from 'ngx-color-picker'
import { CommonModule } from '@angular/common'
import { ToastrModule } from 'ngx-toastr'


import { AppRoutingModule } from './app.routing'
import { ComponentsModule } from './layouts/components/components.module'

import { AppComponent } from './app.component'
import { ComponentsModule as PersonalComponentsModule } from './components/components.module'

import {
  AgmCoreModule
} from '@agm/core'

import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component'
import { Httpclient } from './services/Httpclient.service'
import { LoginService } from './services/Login.service'
import { 
  EstablishmentService, 
  ProductService,
  StockService,
  CategoryService,
  StaffService,
  PrinterService,
  OptionService
} from './services'

@NgModule({
  imports: [
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule.withConfig({warnOnNgModelWithFormControl: 'never'}),
    HttpModule,
    ComponentsModule,
    RouterModule,
    AppRoutingModule,
    NgSelectModule,
    ColorPickerModule,
    CommonModule,
    ToastrModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: 'YOUR_GOOGLE_MAPS_API_KEY'
    }),

    PersonalComponentsModule
  ],
  declarations: [
    AppComponent,
    AdminLayoutComponent,
  ],
  providers: [
    Httpclient,
    LoginService,
    EstablishmentService,
    ProductService,
    StockService,
    CategoryService,
    StaffService,
    PrinterService,
    OptionService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
