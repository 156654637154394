export class Status {
    public status: number
    public code: string
    public msg: string
    public detail: any
    public lastInsertId: number

    constructor (response: any) {
        this.status = response.status
        this.code = response.code
        this.msg = response.msg
        this.detail = response.detail
        this.lastInsertId = response.lastInsertId
    }
}