import { Product } from './product/Product';
import { Type } from './product/Type';
import { Vat } from './product/Vat';
import { Color } from './product/Colors';
import { Association } from './product/Association';
import { Category } from './product/Category';
import { ProductItem } from './product/ProductItem';
import { Stock } from './product/Stock';

import { Establishment } from './establishment/Establishment';
import { Printer } from './establishment/Printer';
import { PrinterPlacement } from './establishment/PrinterPlacement';
import { PrinterKind } from './establishment/PrinterKind';
import { PrinterTarget } from './establishment/PrinterTarget';
import { PrinterModel } from './establishment/PrinterModel';
import { Option } from './establishment/Option';
import { OptionKind } from './establishment/OptionKind';
import { OptionCategory } from './establishment/OptionCategory';
import { Comment } from './establishment/Comment';

import { Staff } from './team/Staff'
import { Role } from './team/Role'

export {
    Product,
    Type,
    Vat,
    Color,
    Association,
    Category,
    ProductItem,
    Stock,

    Establishment,
    Printer,
    PrinterPlacement,
    PrinterKind,
    PrinterTarget,
    PrinterModel,
    Option,
    OptionKind,
    OptionCategory,
    Comment,

    Staff,
    Role
}