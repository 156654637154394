import { Injectable } from '@angular/core'

import { Httpclient } from './Httpclient.service'
import { Endpoint } from 'app/models/Endpoint'
import { Product, Category, Association, ProductItem } from 'app/models'
import { Status } from 'app/models/Status';

@Injectable()
export class ProductService {

    public endpoint: string

    constructor (
        private _Httpclient: Httpclient
    ) {
        this.endpoint = Endpoint["product"].get()
    }

    public async getLast (): Promise<Array<Product>> {
        try {
            return Promise.resolve([
                // new Product(1, "SET", "Menu enfant", 10.00, 10),
                // new Product(2, "SET", "Menu tacos", 7, 10),
                // new Product(3, "EXTEND", "Galette de pommes de terre", 1.00, 10),
                // new Product(4, "SIMPLE", "Tacos Simple", 6.00, 10),
                // new Product(5, "EXTEND", "Nuggets", 0.00, 10),
            ])
        } catch (error) {
            return Promise.reject(error)
        }
    }

    public async getType (type: string): Promise<Array<Product>> {
        try {
            let res = await this._Httpclient.get(`${this.endpoint}/type/${type}`)
            let products = res.map(product => new Product(
                product.product_id, 
                type, 
                product.name, 
                product.color,
                product.product_price.price_wvat,
                product.product_price.vat,
                product.product_price.price_novat,
                null,
                null,
                (type === "SET") ? product.product_set_id : null,
                (type === "EXTEND") ? product.product_extend_id : null,
                product.designation,
                product.description,
                product.printer_placement
            ))
            return Promise.resolve(products)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    public async find(productId: number): Promise<Product> {
        try {
            let res = await this._Httpclient.get(`${this.endpoint}/${productId}`)
            let product = new Product(
                res.product_id, 
                res.type, 
                res.name, 
                res.color,
                res.product_price.price_wvat,
                res.product_price.vat,
                res.product_price.price_novat,
                (res.product_extend_category) ? res.product_extend_category.map(category => new Category(
                    category.name,
                    category.min,
                    category.max,
                    (category.product) ? category.product.map(association => new Association(
                        (res.type === "SET") ? "SET" : "EXTEND",
                        association.product_id,
                        (res.type === "SET") ? category.category_set_id : category.category_extend_id,
                        (res.type === "SET") ? association.product_set_id : null,
                        (res.type !== "SET") ? association.product_extend_id : null,
                        association.order,
                        association.name
                    )) : [],
                    (res.type === "SET") ? category.category_set_id : category.category_extend_id,
                    (res.type === "SET") ? "SET" : "EXTEND"
                )) : [],
                (res.product_item) ? res.product_item.map(item => new ProductItem(
                    res.product_id,
                    (item.name) ? item.name : item.stock.nomination,
                    item.quantity,
                    item.stock_id,
                    item.product_item_id
                )) : [],
                (res.type === "SET") ? res.product_set_id : null,
                (res.type === "EXTEND") ? res.product_extend_id : null,
                res.designation,
                res.description,
                res.printer_placement
            )
            return Promise.resolve(product)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    public async findTypeCount (type: string): Promise<number> {
        try {
            let products = await this.getType(type)
            let count = products.length
            return Promise.resolve(count)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    public async add (product: Product): Promise<Status> {
        try {
            let res = await this._Httpclient.post(`${this.endpoint}`, product)
            return Promise.resolve(new Status(res))
        } catch (error) {
            return Promise.reject(error)
        }
    }

    public async edit (id: number, product: Product): Promise<Status> {
        try {
            let res = await this._Httpclient.put(`${this.endpoint}/${id}`, product)
            return Promise.resolve(new Status(res))
        } catch (error) {
            return Promise.reject(error)
        }
    }

    public async delete (id: number): Promise<Status> {
        try {
            let res = await this._Httpclient.delete(`${this.endpoint}/${id}`)
            return Promise.resolve(new Status(res))
        } catch (error) {
            return Promise.reject(error)
        }
    }
}