export class Option {
    public config_option_id: number
    public config_category_id: number
    public name: string
    public code: string
    public value: string|boolean
    public description: string
    public kind: string

    constructor(config_category_id?: number, name?: string, code?: string, value?: string|boolean, description?: string, kind?: string, config_option_id?: number) {
        this.config_option_id = config_option_id
        this.config_category_id = config_category_id
        this.name = name
        this.code = code
        this.value = value
        this.description = description
        this.kind = kind
    }
}