import { Injectable } from '@angular/core'
import { Httpclient } from "./Httpclient.service"

import { Endpoint } from "app/models/Endpoint"
import { OptionCategory, Option, Comment } from "app/models"
import { Status } from 'app/models/Status';

@Injectable()
export class OptionService {

    public endpoint: string

    constructor(
        private _Httpclient: Httpclient
    ) {
        this.endpoint = Endpoint["options"].get()
    }

    public async get (): Promise<Array<Option>> {
        try {
            let res = await this._Httpclient.get(`${this.endpoint}`)
            let options = res.map(option => new Option(
                option.config_category_id, 
                option.name, 
                option.code, 
                (option.kind === "TOGGLE")
                    ? option.value === "ENABLE"
                    : option.value, 
                option.description, 
                option.kind, 
                option.config_option_id
            ))
            return Promise.resolve(options)
        } catch (error) {
            console.error("OptionService:get", error)
            let err = await this._Httpclient.error(error)
            return Promise.reject(err)
        }
    }

    public async getComment (): Promise<Array<Comment>> {
        try {
            let res = await this._Httpclient.get(`${this.endpoint}/comment`)
            let comments = res.map(comment => new Comment(comment.comment, comment.config_comment_id))
            return Promise.resolve(comments)
        } catch (error) {
            console.error("OptionService:getComment", error)
            let err = await this._Httpclient.error(error)
            return Promise.reject(err)
        }
    }

    public async getCategories (): Promise<Array<Option>> {
        try {
            let res = await this._Httpclient.get(`${this.endpoint}/category`)
            let categories = res.map(category => new OptionCategory(category.name, category.code, category.description, category.config_category_id))
            return Promise.resolve(categories)
        } catch (error) {
            console.error("OptionService:getCategories", error)
            let err = await this._Httpclient.error(error)
            return Promise.reject(err)
        }
    }

    public async addComment (comment: string): Promise<Status> {
        try {
            let data = {comment: comment}
            let res = await this._Httpclient.post(`${this.endpoint}/comment`, data)
            return Promise.resolve(res)
        } catch (error) {
            console.error("OptionService:addComment", error)
            let err = await this._Httpclient.error(error)
            return Promise.reject(err)
        }
    }

    public async update (options: Array<Option>): Promise<Status> {
        try {
            let data: Array<Option> = options.map(option => {
                return {
                    ...option,
                    value: (option.kind === "TOGGLE") 
                        ? (option.value) ? "ENABLE" : "DISABLE"
                        : option.value
                }
            })
            let res = await this._Httpclient.put(`${this.endpoint}`, data)
            return Promise.resolve(res)
        } catch (error) {
            console.error("OptionService:update", error)
            let err = await this._Httpclient.error(error)
            return Promise.reject(err)
        }
    }

    public async deleteComment (id: number): Promise<Status> {
        try {
            let res = await this._Httpclient.delete(`${this.endpoint}/comment/${id}`)
            return Promise.resolve(res)
        } catch (error) {
            console.error("OptionService:deleteComment", error)
            let err = await this._Httpclient.error(error)
            return Promise.reject(err)
        }
    }
}