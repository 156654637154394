export class PrinterTarget {
    public printer_id: number
    public printer_target_id: number
    public model: string
    public mac_address: string

    constructor(model?: string, mac_address?: string, printer_id?: number, printer_target_id?: number) {
        this.printer_id = printer_id
        this.printer_target_id = printer_target_id
        this.model = model
        this.mac_address = mac_address
    }
}