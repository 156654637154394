export class OptionCategory {
    public config_category_id: number
    public name: string
    public code: string
    public description: string

    constructor(name?: string, code?: string, description?: string, config_category_id?: number) {
        this.config_category_id = config_category_id
        this.name = name
        this.code = code
        this.description = description
    }
}