export class PrinterKind {
    public label: string
    public value: string

    constructor (label: string, value: string) {
        this.label = label
        this.value = value
    }

    public static list: Array<PrinterKind> = [
        new PrinterKind("Ticket", "TICKET"),
        new PrinterKind("Préparation", "PREPARATION")
    ]
}