export class OptionKind {
    public label: string
    public value: string

    constructor(label: string, value: string) {
        this.label = label
        this.value = value
    }

    public static list: Array<OptionKind> = [
        new OptionKind("Champ libre", "TEXT"),
        new OptionKind("Activé/désactivé", "TOGGLE"),
        new OptionKind("Caché", "HIDDEN")
    ]
}