export class PrinterModel {
    public label: string
    public value: string

    constructor (label: string, value: string) {
        this.label = label
        this.value = value
    }

    public static list: Array<PrinterModel> = [
        new PrinterModel("Tm-t20", "TMT20"),
        new PrinterModel("Tm-t88", "TMT88")
    ]
}