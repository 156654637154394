import { Injectable } from '@angular/core'
import { Endpoint } from "app/models/Endpoint"
import { Http, Headers } from '@angular/http';

@Injectable()
export class LoginService {

    private endpoint: string

    constructor(
        private _Http: Http
    ) {
        this.endpoint = Endpoint["auth"].get()
    }

    public async auth (accessKey: string): Promise<void> {
        try {
            let headers = new Headers();
            headers.append("Domain", "MANAGER")
            headers.append("Content-Type", "application/json")
            //headers.append("Version", "2.0")
            headers.append("Version", "dev")
            let res = await this._Http.post(`${this.endpoint}/sbz/login`, {
                username: "system",
                password: "2bced1f5f0de72aa7accdf370e15a13c",
                accessKey
            }, {headers}).toPromise()
            console.log(res.json())
            localStorage.setItem("token", res.json().token)
            return Promise.resolve()
        } catch (error) {
            console.error("LoginService:auth", error)
            return Promise.reject(error)
        }
    }
}