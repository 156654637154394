import { identifierModuleUrl } from "@angular/compiler";

export class Stock {
    public stock_id: number
    public name: string

    constructor (stock_id?: number, name?: string) {
        this.stock_id = stock_id
        this.name = name
    }
}