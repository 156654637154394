export class Association {
    // Set Association
    public product_set_assoc_id: number
    public product_set_id: number
    public category_set_id: number
    // Extend Association
    public product_extend_assoc_id: number
    public product_extend_id: number
    public category_extend_id: number
    // Common
    public product_id: number
    public order: number
    public name: string

    constructor (type:string, productId: number, categoryId: number, productSetId?: number, productExtendId?: number, order?: number, name?: string) {
        this.product_extend_id = productExtendId
        this.product_set_id = productSetId
        this.product_id = productId
        if (type === "SET") this.category_set_id = categoryId
        if (type === "EXTEND") this.category_extend_id = categoryId
        this.order = order
        this.name = name
    }
}