import { Injectable } from '@angular/core'
import { Httpclient } from "./Httpclient.service"

import { Establishment } from "app/models/establishment/Establishment"
import { Endpoint } from "app/models/Endpoint"

@Injectable()
export class EstablishmentService {

    constructor(
        private _Httpclient: Httpclient
    ) {
        
    }

    public async get (): Promise<Array<Establishment>> {
        try {
            let res = await this._Httpclient.get(`${Endpoint["auth"].get()}/sbz/establishment?customer=1`, "c3ltYmlvejpkNGQ0OTY1YjI0YjRlYmM4MzQxZTc2M2NlNjliMjFjOA==")
            let establishments = res.map(establishment => new Establishment(establishment.trade_name, establishment.access_key))
            return Promise.resolve(establishments)
        } catch (error) {
            console.error("EstablishmentService:get", error)
            let err = await this._Httpclient.error(error)
            return Promise.reject(err)
        }
    }

    public async find (): Promise<Establishment> {
        try {
            let res = await this._Httpclient.get(`${Endpoint["common"].get()}/establishment`)
            let establishment = new Establishment(
                res.trade_name,
                res.access_key,
                true,
                res.trade_name,
                res.corporate_name,
                res.tva,
                res.siret,
                res.naf,
                res.longitude,
                res.latitude,
                res.phone,
                res.address,
                res.postal_code,
                res.city
            )
            return Promise.resolve(establishment)
        } catch (error) {
            console.error("EstablishmentService:find", error)
            let err = await this._Httpclient.error(error)
            return Promise.reject(err)
        }
    }
}