export class Color {
    public label: string
    public value: string

    constructor (label: string, value: string) {
        this.label = label
        this.value = value
    }

    public static list: Array<Color> = [
        new Color("Blanc", "COLOR_WHITE"),
        new Color("Bleu", "COLOR_BLUE"),
        new Color("Vert", "COLOR_GREEN"),
        new Color("Rouge", "COLOR_RED"),
        new Color("Cyan", "COLOR_CYAN"),
        new Color("Jaune", "COLOR_YELLOW"),
        new Color("Violet", "COLOR_PURPLE"),
        new Color("Noir", "COLOR_BLACK")
    ]
}